const t = {
  "gray-900-90": "rgba(30, 36, 49, 0.9)",
  "gray-900-50": "rgba(30, 36, 49, 0.5)",
  "gray-900-30": "rgba(30, 36, 49, 0.3)",
  "gray-800-90": "rgba(76, 83, 99, 0.9)",
  "gray-800-50": "rgba(76, 83, 99, 0.5)",
  "white-80": "rgba(255, 255, 255, 0.80)",
  "white-65": "rgba(255, 255, 255, 0.65)",
  "white-30": "rgba(255, 255, 255, 0.30)",
  "gold-20": "rgba(214, 203, 158, 0.2)",
  "gold-50": "rgba(214, 203, 158, 0.5)",
  "gold-70": "rgba(214, 203, 158, 0.7)"
}, o = {
  gray: {
    0: "#FFF",
    50: "#F2F1F6",
    100: "#E7E7ED",
    200: "#D5D5DC",
    300: "#BDBFC7",
    400: "#9DA2AC",
    500: "#888E99",
    600: "#7B818C",
    700: "#5F6674",
    800: "#4C5363",
    900: "#1E2431",
    1e3: "#11141A",
    1100: "#0A0C0F"
  },
  red: {
    50: "#FFF7F3",
    100: "#FEF1ED",
    200: "#FDDCD5",
    300: "#FBCCC5",
    400: "#F9B6AE",
    500: "#F8A7A0",
    600: "#F68A8E",
    700: "#F0555E",
    800: "#DE3355",
    900: "#CC104B",
    1e3: "#C40843"
  },
  orange: {
    50: "#FFF9EA",
    100: "#FFF6E5",
    200: "#FFEED8",
    300: "#FFE4C9",
    400: "#FFD8B6",
    500: "#FFD0A8",
    600: "#FFB894",
    700: "#FF9667",
    800: "#FC7246",
    900: "#F05828",
    1e3: "#E34B1B"
  },
  green: {
    50: "#F5FEF9",
    100: "#F3FDF6",
    200: "#E5F5E4",
    300: "#DDF1D9",
    400: "#C9EACC",
    500: "#B2DCB3",
    600: "#81C594",
    700: "#67B678",
    800: "#49A47E",
    900: "#35846B",
    1e3: "#2F7A62"
  },
  gold: "#c9b17f",
  avatar: {
    0: "#E09489",
    1: "#E2B37E",
    2: "#E2C495",
    3: "#D9A8A3",
    4: "#B4C8CC"
  },
  gradient: {
    red: "linear-gradient(135deg, #F69396 15%, #F0555E 45%, #DE3355 85%)",
    green: "linear-gradient(135deg, #C7E9CB 15%, #49A47E 45%, #35846B 85%)",
    gold: "linear-gradient(135deg, #E8DEB4 0%, #C1A875 70%)"
  },
  transparent: t,
  t,
  warm: {
    900: "#383934",
    500: "#D6CB9E"
  }
}, e = "https://rumiai.notion.site", a = `${e}/The-Waitroom-Experience-e859705ac3284030b05d20b0d2b2b74c`, i = {
  routes: {
    home: "/",
    host: "/host",
    notFound: "/404"
  },
  facebook: {
    appId: ""
  },
  company: {
    name: "Rumi",
    domain: "rumi.ai",
    emails: {
      default: "info@rumi.ai",
      contact: "hello@rumi.ai",
      support: "support@rumi.ai",
      deleteAccount: "deleteaccount@rumi.ai",
      community: "community@rumi.ai",
      sales: "sales@rumi.ai"
    },
    socials: {
      twitter: "https://twitter.com/rumidotai",
      youtube: "https://www.youtube.com/@RumiDotAI",
      linkedin: "https://www.linkedin.com/company/rumiai",
      instagram: "https://www.instagram.com/rumidotai"
    }
  },
  maxImageSize: 5242880,
  // 5MB
  links: {
    notion: e,
    onboarding: "https://calendly.com/aroder-rumi",
    googleCalendar: "https://workspace.google.com/u/0/marketplace/app/waitroom/732264686334",
    outlookCalendar: "https://appsource.microsoft.com/en-us/product/Office365/WA200007671",
    chromeExtension: "https://chromewebstore.google.com/detail/rumi-chrome-extension/hjikepnkfllnjjphbbobfppkegimelpn",
    paddle: {
      support: "https://help.paddle.com/hc/en-us/articles/115002266545-How-do-I-get-in-touch-with-Paddle-support-"
    },
    knowledgebase: {
      default: `${e}/Rumi-Knowledgebase-2c2fb04d0aba418987c553813d041fb6`,
      pressKit: `${e}/Rumi-brand-assets-7ed58925f54c4196937a70ea4216ebbe?pvs=4`,
      hostsInfo: `${e}/Guide-to-Hosting-on-Waitroom-1157fe7389b24c57ab40e3b8f0cae0f0`,
      acknowledgements: `${e}/Acknowledgements-583bf312c0574df7840bf5327247a53e`,
      gettingStarted: `${e}/What-to-expect-when-hosting-your-first-Waitroom-59ca069525f0430d8aee2bd1fff5871b`,
      privateSessions: `${e}/Private-sessions-on-Waitroom-97a6490c58f6415a90d7fe00db6403b6`,
      experience: a,
      producerSafePlace: `${a}#be7c0efb471f40af9340c642eaa4ff08`,
      timeWithHost: `${a}#0705f6c63118414d95767181ba8a55e3`,
      timeAndQueue: `${a}#3b8b16cde2ed4388b54c58b6e5fd7e8a`,
      chatClipping: `${a}#59b3bd0d283e4ab987e2c7f907f5b504`,
      useWithCalendly: `${e}/todo`
      // ! TODO: fixme
    },
    affiliate: "/landing/affiliate-program",
    terms: "/landing/terms",
    privacy: "/landing/privacy",
    careers: "/landing/careers",
    feedback: "https://rumiai.typeform.com/to/hTMnmlON",
    chrome: "https://www.google.com/chrome/",
    firefox: "https://www.mozilla.org/en-US/firefox/new/",
    edge: "https://www.microsoft.com/en-us/edge",
    apps: {
      googlePlay: "https://play.google.com/store/apps/details?id=com.waitroom",
      appleApp: "https://apps.apple.com/us/app/waitroom/id1561696428"
    }
  },
  sanity: {
    appId: "6diaohap",
    apiUrl: "https://6diaohap.api.sanity.io/v2023-01-19/data/query/production",
    cdnUrl: "https://cdn.sanity.io"
  },
  segment: {
    trackUrl: "https://api.segment.io/v1/track"
  },
  paddle: {
    id: 178351
  }
};
export {
  o as colors,
  i as config
};
