import { SessionAccessRuleStatus as P } from "@waitroom/models";
import { buildSuccessRequestResponse as j, aiApiService as U, calendarApiService as se, sessionApiService as p, cmsApiService as z, integrationsApiService as v, lobbyApiService as b, meetingMemoryApiService as H, recordingApiService as J, sessionAccessApiService as D, authApiService as ne, userApiService as B, subscriptionPlanApiService as re, tokenApiService as oe, userMeetingTypesService as ue } from "@waitroom/common-api";
import { useQueryClient as X, useQuery as Z, useInfiniteQuery as ie, QueryObserver as V } from "@tanstack/react-query";
import { braidService as ae } from "@waitroom/braid";
import { applyPatch as ye, deepClone as Ee } from "fast-json-patch";
import { useEffect as d, useMemo as _e, useRef as Se, useState as W, useCallback as ge } from "react";
const h = (e) => typeof e == "function", M = ({
  client: e,
  key: t,
  fn: s,
  skipEmpty: n
}) => {
  const r = e.getQueryData(t);
  n && r === void 0 || e.setQueryData(t, s(r));
}, Q = (e) => M({
  ...e,
  fn: (t) => ({
    code: 200,
    success: !0,
    ...t,
    data: h(e.dataOrFn) ? e.dataOrFn(t == null ? void 0 : t.data) : e.dataOrFn
  })
}), ce = {
  update: Q,
  addToArray: (e) => Q({
    ...e,
    dataOrFn: (t) => [
      ...Array.isArray(e.data) ? e.data : [e.data],
      ...t || []
    ]
  }),
  updateInArray: ({
    data: e,
    keyId: t,
    ...s
  }) => Q({
    ...s,
    dataOrFn: (n) => n == null ? void 0 : n.map((r) => r[t] === e[t] ? e : r)
  }),
  removeFromArray: ({
    id: e,
    keyId: t,
    ...s
  }) => Q({
    ...s,
    dataOrFn: (n) => n == null ? void 0 : n.filter((r) => r[t] !== e)
  }),
  addToPages: (e) => M({
    ...e,
    fn: (t) => {
      var n, r, o;
      const s = {
        pageParams: [...(t == null ? void 0 : t.pageParams) || []],
        pages: [...(t == null ? void 0 : t.pages) || []]
      };
      return s.pages[0] = {
        ...s.pages[0],
        data: {
          code: 200,
          success: !0,
          ...(n = s.pages[0]) == null ? void 0 : n.data,
          data: e.updateFn((o = (r = s.pages[0]) == null ? void 0 : r.data) == null ? void 0 : o.data)
        }
      }, s;
    }
  }),
  updateInPages: (e) => M({
    ...e,
    fn: (t) => {
      var n, r;
      if (!t || !((n = t.pages) != null && n.length)) return t;
      const s = {
        pageParams: [...t.pageParams],
        pages: [...t.pages]
      };
      for (let o = 0; o < s.pages.length; o++) {
        const u = (r = s.pages[o]) == null ? void 0 : r.data, i = u == null ? void 0 : u.data;
        if (!i) continue;
        const y = e.updateFn(i);
        if (u !== y && (s.pages[o] = {
          ...s.pages[o],
          data: {
            ...u,
            data: y
          }
        }, e.stopOnChange))
          break;
      }
      return s;
    }
  })
}, me = (e) => M({
  ...e,
  fn: (t) => {
    var s;
    return {
      code: 200,
      success: !0,
      ...t,
      data: {
        code: 200,
        success: !0,
        ...t == null ? void 0 : t.data,
        data: h(e.dataOrFn) ? e.dataOrFn((s = t == null ? void 0 : t.data) == null ? void 0 : s.data) : e.dataOrFn
      }
    };
  }
}), Ce = {
  update: me
}, m = {
  update: M,
  request: ce,
  apiRequest: Ce
}, G = "private", ee = "calendarEvent", le = "cmsPage", Ae = "cmsPages", Mt = "currentUser", pe = "dashboardPastSessions", de = "meetingMemorySessions", bt = "pastSessions", Re = "dashboardSessions", Dt = "featuredSessions", Qt = "integrationsConnections", Nt = "integrationsDirectURL", Yt = "integrationsGetAction", Oe = "integrationsProviders", Ke = "meetingMemoryMessages", vt = "meetingMemorySuggestions", fe = "meetingMemoryThread", Te = "meetingMemoryThreads", qe = "paymentDetails", Fe = "recording", Ht = "recordingStatus", Bt = "session", Pe = "session-v2", Gt = "sessionAccessRule", L = "sessionAccessRules", Me = "sessionAiFeed", be = "sessionEndSummary", De = "sessionEpisodes", It = "sessionPendingRulesStats", Qe = "sessionPresence", te = "sessionRequestAccess", Lt = "sessionViewers", Ne = "sessionCards", Ye = "streamToken", ve = "listeningModeSpeakers", He = "subscriptionPlans", Be = "subscriptionReceipts", Ge = "user", Ie = "integrationsGetByOwnerSessionRecurrence", Le = "userMeetingTypes-v2", kt = "branch", Ut = "salesforceProvider", $t = "salesforceGetBindingById", wt = "salesforceGetAccountById", xt = "salesforceGetOpportunityById", Vt = "salesforceListBindableRecordsAsOptions", Wt = "salesforceGetContacts", jt = "salesforceGetLeadById", zt = "hubspotProvider", Jt = "hubspotGetBindingById", ke = "hubspotGetCompanyById", Xt = "hubspotGetDealById", Zt = "hubspotListBindableRecordsAsOptions", ht = "hubspotGetContacts", es = "hubspotGetContactById", Ue = "lobbyEnter", $e = "lobbySummary", ts = (e, t) => [G, ...e, t], N = (e, t = "") => [
  Me,
  e,
  t
], k = (e, t = "") => [
  te,
  e,
  t,
  P.IN_REVIEW
], we = (e, t, s) => [te, e, t, s || ""], xe = (e, t, s) => [Pe, e, t || "", s || ""], Ve = (e, t = "") => [
  Qe,
  e,
  t
], We = (e) => [Ge, e], $ = (e) => [
  Te,
  e
], ss = (e) => [fe, e], q = (e) => [
  Ke,
  e
], ns = (e, t) => [
  ve,
  e,
  t
], je = (e, t) => [
  be,
  e,
  t
], ze = (e) => [Ue, e], Je = (e) => [$e, e], Xe = {
  add: ({ client: e, data: t, sessionId: s, recurrenceId: n }) => {
    t && m.request.addToArray({
      client: e,
      key: N(s, n),
      data: [t]
    });
  },
  update: ({ client: e, data: t, sessionId: s, recurrenceId: n }) => {
    t && m.request.update({
      client: e,
      key: N(s, n),
      dataOrFn: (r) => r ? (r.messages = r.messages.map((o) => o.id === t.id ? t : o), r) : { messages: [t], totalSummaryCount: 1 }
    });
  },
  remove: ({ client: e, sessionId: t, recurrenceId: s, id: n }) => {
    m.request.removeFromArray({
      client: e,
      key: N(t, s),
      id: n,
      keyId: "id"
    });
  }
}, rs = {
  ...Xe
}, Ze = {
  addMessage: ({ client: e, data: t, id: s }) => {
    t && m.apiRequest.update({
      client: e,
      key: q(s),
      dataOrFn: (n) => ({
        hasMore: !1,
        ...n,
        messages: [...(n == null ? void 0 : n.messages) || [], t],
        total: ((n == null ? void 0 : n.total) || 0) + 1
      })
    });
  },
  updateMessage: ({ client: e, data: t, id: s }) => {
    t && m.apiRequest.update({
      client: e,
      key: q(s),
      dataOrFn: (n) => n != null && n.messages ? {
        ...n,
        messages: n.messages.map((r) => r.id === t.id ? { ...r, ...t } : r)
      } : n
    });
  },
  updateLastAiMessage: ({ client: e, data: t, id: s }) => {
    t && m.apiRequest.update({
      client: e,
      key: q(s),
      dataOrFn: (n) => {
        var y;
        const r = n && {
          ...n,
          messages: n.messages ? [...n.messages] : void 0
        };
        if (!(r != null && r.messages)) return n;
        const o = r.messages.findLastIndex(
          (c) => (!t.id || c.id === t.id) && c.role === "ai"
        );
        if (o === -1) return n;
        const u = r.messages[o], i = { ...u, ...t, id: t.id || u.id };
        return t.content && (i.content = `${u.content || ""}${t.content}`), t.todo && (i.todo = `${u.todo || ""}${t.todo}`), t.progress && (i.progress = `${u.progress || ""}${t.progress}`), r.messages[o] = i, !((y = i.content) != null && y.length) && t.isComplete && r.messages.splice(o, 1), r;
      }
    });
  },
  removeMessage: ({ client: e, id: t, messageId: s }) => {
    m.apiRequest.update({
      client: e,
      key: q(t),
      dataOrFn: (n) => {
        var u;
        if (!(n != null && n.messages)) return n;
        const r = n.messages.length, o = (u = n.messages) == null ? void 0 : u.filter((i) => i.id !== s);
        return {
          ...n,
          messages: o,
          total: Math.max((n.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  },
  removeLastMessage: ({ client: e, id: t }) => {
    m.apiRequest.update({
      client: e,
      key: q(t),
      dataOrFn: (s) => s != null && s.messages ? {
        ...s,
        messages: s.messages.slice(0, -1),
        total: Math.max((s.total || 0) - 1, 0)
      } : s
    });
  },
  removeThread: ({ client: e, id: t, userId: s }) => {
    m.apiRequest.update({
      client: e,
      key: $(s),
      dataOrFn: (n) => {
        var u;
        if (!(n != null && n.threads)) return n;
        const r = n.threads.length, o = (u = n.threads) == null ? void 0 : u.filter((i) => i.id !== t);
        return {
          ...n,
          threads: o,
          total: Math.max((n.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  }
}, os = Ze;
let O;
const us = (e) => {
  O = e.logger;
}, he = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, is = (e) => he(e.data), et = (e, t = 0) => {
  var s, n;
  return (n = (s = e == null ? void 0 : e.pages[t]) == null ? void 0 : s.data) == null ? void 0 : n.data;
}, as = (e, t = 0) => et(e.data, t), tt = (e, t, s = void 0, ...n) => {
  try {
    return ye(e, t, ...n).newDocument;
  } catch (r) {
    return O == null || O.logLvl(3, r, e, t), O == null || O.report(r), s;
  }
}, ys = (e, t, s) => {
  if (e.op !== "add" || !e.path.endsWith("/-")) return !0;
  const n = e.path.split("/").slice(1, -1);
  let r = s;
  for (const o of n)
    r[o] === null && (r[o] = []), r = r[o];
  return !0;
}, Es = (e) => {
  const t = X(), { queryFn: s, queryKey: n, braidKey: r } = e, o = Z({
    ...e,
    queryFn: typeof s == "function" ? async (...u) => {
      const i = await s(...u);
      return t.getQueryData(n) ?? i;
    } : void 0
  });
  return d(() => () => {
    ae.close(r);
  }, [r]), o;
}, st = (e, t, s) => {
  t.setQueryData(s, e);
}, nt = (e, t, s) => {
  t.setQueryData(
    s,
    (n = j()) => tt(Ee(n), e) ?? n
  );
}, K = (e, t) => ({
  onFetched: (s) => st(s, t, e),
  onPatch: (s) => nt(s, t, e)
}), _s = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1
}, w = (e) => (t) => ({
  gcTime: t,
  staleTime: t,
  retry: 2,
  refetchOnMount: !0,
  refetchOnReconnect: e,
  refetchOnWindowFocus: e
}), a = {
  "7d": 6048e5,
  "3d": 2592e5,
  "1d": 864e5,
  "6h": 216e5,
  "1h": 36e5,
  "30m": 18e5,
  "15m": 9e5,
  "10m": 6e5,
  "5m": 3e5,
  "3m": 18e4,
  "1m": 6e4,
  "30s": 3e4,
  "15s": 15e3,
  "5s": 5e3,
  "3s": 3e3,
  "1s": 1e3
}, E = [""], l = () => {
}, S = w(!0), F = {
  "7d": S(a["7d"]),
  "3d": S(a["3d"]),
  "1d": S(a["1d"]),
  "6h": S(a["6h"]),
  "1h": S(a["1h"]),
  "30m": S(a["30m"]),
  "15m": S(a["15m"]),
  "10m": S(a["10m"]),
  "5m": S(a["5m"]),
  "3m": S(a["3m"]),
  "1m": S(a["1m"]),
  "30s": S(a["30s"]),
  "15s": S(a["15s"]),
  "3s": S(a["3s"]),
  0: S(0)
}, g = w(!1), C = {
  "7d": g(a["7d"]),
  "3d": g(a["3d"]),
  "1d": g(a["1d"]),
  "6h": g(a["6h"]),
  "1h": g(a["1h"]),
  "30m": g(a["30m"]),
  "15m": g(a["15m"]),
  "10m": g(a["10m"]),
  "5m": g(a["5m"]),
  "3m": g(a["3m"]),
  "1m": g(a["1m"]),
  "30s": g(a["30s"]),
  "15s": g(a["15s"]),
  "3s": g(a["3s"]),
  0: g(0)
}, R = (e = 0) => ({
  ...w(!1)(e),
  refetchOnReconnect: !0
}), Ss = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e, u = o ? N(e, t) : E, i = `get-session-ai-feed-${e}-${t}`;
  return {
    ...R(0),
    ...n,
    queryKey: u,
    braidKey: i,
    enabled: o,
    queryFn: o ? ({ signal: y }) => U.getFeed(e, t, {
      ...K(u, s),
      ...r,
      key: i,
      signal: y,
      params: {
        limit: 200,
        sortOrder: "asc",
        ...r == null ? void 0 : r.params
      }
    }) : l
  };
}, gs = ({
  sessionId: e,
  recurrenceId: t,
  options: s,
  params: n
}) => ({
  ...s,
  mutationFn: (r) => U.event(e, t, {
    ...n,
    data: r
  })
}), cs = ({
  sessionId: e,
  recurrenceId: t
}) => ({
  queryKey: je(e, t),
  queryFn: () => U.getSummary(e, t),
  ...C["5m"]
}), ms = (e) => [ee, e], Cs = (e) => ({
  queryKey: [ee, e],
  queryFn: () => se.getCalndrLinkEvent({ data: e })
}), rt = (e, t) => [
  Ne,
  e,
  t
], ls = ({
  sessionId: e,
  sessionRecurrenceId: t,
  queryClient: s,
  options: n
}) => {
  const r = !!e && !!t, o = r ? rt(e, t) : E, u = `get-cms-cards-${e}-${t}`;
  return {
    ...R(0),
    queryKey: o,
    braidKey: u,
    enabled: r,
    queryFn: r ? () => p.cards(e, t, {
      ...K(o, s),
      key: u
    }) : l,
    ...n
  };
}, ot = (e) => [le, e], As = ({
  slug: e,
  isPreview: t
}) => ({
  queryKey: e ? ot(e) : E,
  queryFn: () => z.getPage(e || ""),
  ...C[t ? 0 : "5m"],
  enabled: !!e
}), ut = (e) => [Ae, e], ps = ({
  types: e,
  isPreview: t
}) => ({
  queryKey: ut(e),
  queryFn: async () => {
    var o;
    const s = await z.getPages(e);
    if (!((o = s == null ? void 0 : s.data) != null && o.result)) throw s;
    const r = s.data.result.reduce(
      (u, i) => i.type ? i.type === "general" ? (i.category && (u.general[i.category] = [...u[i.type][i.category] || [], i]), u) : (u[i.type] = [...u[i.type] || [], i], u) : u,
      {
        general: {
          // here because of the order
          features: [],
          solutions: [],
          compare: [],
          resources: [],
          product: [],
          none: []
        }
      }
    );
    return {
      ...s,
      grouped: r
    };
  },
  ...C[t ? 0 : "15m"],
  gcTime: t ? 0 : 864e5
  // 1 day
}), it = (e, t) => [Ie, e, t], ds = ({
  sessionId: e,
  recurrenceId: t,
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e && !!t, u = o ? it(e, t) : E, i = `crmBindings-${e}-${t}`;
  return {
    queryKey: u,
    braidKey: i,
    enabled: o,
    ...R(0),
    ...n,
    queryFn: o ? () => v.getBindingsByOwnerSessionRecurrencePerProvider(
      e,
      t,
      {
        key: i,
        ...K(u, s),
        ...r
      }
    ) : l
  };
}, Rs = ({
  params: e,
  ...t
}) => ({
  ...t,
  mutationFn: ({
    sessionId: s,
    sessionRecurrenceId: n,
    record: { id: r, type: o }
  }) => v.hubspot.createBinding({
    ...e,
    data: {
      sessionId: s,
      sessionRecurrenceId: n,
      record: {
        id: r,
        type: o
      }
    }
  })
}), at = (e) => [ke, e], Os = ({
  id: e,
  params: t,
  ...s
}) => {
  const n = !!e;
  return {
    ...C["1m"],
    ...s,
    enabled: n,
    queryKey: n ? at(e) : E,
    queryFn: n ? () => v.hubspot.getCompanyById(e, t) : l
  };
}, yt = [Oe], Ks = {
  queryKey: yt,
  queryFn: v.getProviders,
  ...C["1h"]
}, fs = (e) => {
  const t = ie(e), { onSuccess: s, onError: n } = e, { isError: r, isSuccess: o, error: u, data: i } = t;
  return d(() => {
    o && s && s(i);
  }, [i, o, s]), d(() => {
    r && n && n(u);
  }, [u, r, n]), t;
}, Ts = (e) => {
  const t = Z(e), { onSuccess: s, onError: n } = e, { isError: r, isSuccess: o, error: u, data: i } = t;
  return d(() => {
    o && s && s(i);
  }, [i, o, s]), d(() => {
    r && n && n(u);
  }, [u, r, n]), t;
}, Et = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1,
  refetchInterval: !1,
  useErrorBoundary: !1,
  enabled: !1
}, qs = (e, t) => {
  const s = X(), n = _e(
    () => ({
      queryKey: e,
      ...Et,
      ...t
    }),
    [t, e]
  ), r = Se(n), [o, u] = W(
    () => new V(s, n)
  ), [i, y] = W(
    o == null ? void 0 : o.getOptimisticResult(n)
  );
  d(() => {
    r.current = n;
  }, [n]), d(() => {
    if (!(e != null && e.length)) return;
    const _ = new V(s, r.current);
    u(_);
    const A = _.getOptimisticResult(r.current);
    y(A);
  }, [s, e]), d(() => {
    const _ = o.subscribe((A) => {
      y(A);
    });
    return () => {
      _();
    };
  }, [o]);
  const c = ge(() => {
    const _ = o.getOptimisticResult(r.current);
    y(_);
  }, [o]);
  return { ...i, refresh: c };
}, Fs = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (s) => b.requestAccess(s.slug, {
    ...t,
    data: s
  })
}), Ps = ({
  options: e,
  params: t
} = {}) => ({
  ...e,
  mutationFn: (s) => b.updateSlug(s.slug, s.lobbyID, {
    ...t,
    data: s
  })
}), Ms = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (s) => b.startLobbySession(s.lobbyID, s.session, s.participants, {
    ...t,
    data: s
  })
}), bs = ({
  queryClient: e,
  id: t,
  options: s
}) => {
  const n = t ? ze(t) : E, r = `lobby-enter-${t}`;
  return {
    ...R(0),
    ...s,
    queryKey: n,
    braidKey: r,
    enabled: !!t,
    queryFn: t ? () => b.enter(t, {
      ...K(n, e),
      key: r
    }) : l
  };
}, Ds = ({
  slug: e,
  options: t
}) => {
  const s = e ? Je(e) : E;
  return {
    ...t,
    queryKey: s,
    queryFn: e ? () => b.summary(e) : l,
    retry: (n, r) => r.code === 404 ? !1 : n < 4
  };
}, Qs = ({
  userId: e,
  options: t,
  params: s
}) => {
  const n = !!e, r = n ? $(e) : E;
  return {
    ...F["3m"],
    ...t,
    queryKey: r,
    queryFn: n ? () => H.threads(s) : l
  };
}, Ns = ({
  id: e,
  options: t,
  params: s
}) => {
  const n = !!e, r = n ? $(e) : E;
  return {
    ...C["3m"],
    ...t,
    queryKey: r,
    enabled: n,
    queryFn: n ? () => H.thread(e, s) : l
  };
}, Ys = ({
  id: e,
  options: t,
  params: s,
  onFetch: n
}) => {
  const r = !!e, o = r ? q(e) : E;
  return {
    ...C["3m"],
    queryKey: o,
    enabled: r,
    ...t,
    queryFn: r ? () => (n == null || n(), H.threadMessages(e, s)) : l
  };
}, vs = ({
  id: e,
  options: t,
  params: s
}) => ({
  ...t,
  mutationFn: (n) => H.deleteThread(e, {
    ...s,
    data: n
  })
}), _t = (e) => [
  De,
  e
], Hs = (e) => ({
  queryKey: e ? _t(e) : E,
  queryFn: e ? () => J.getEpisodes(e) : l,
  ...C["5m"],
  enabled: !!e
}), St = (e, t) => [
  Fe,
  e,
  t
], Bs = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? St(e, t) : E,
    ...C["15m"],
    queryFn: s ? () => J.getRecording(e, t) : l,
    enabled: s
  };
}, Gs = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = !!e, u = o ? k(e, t) : E, i = `get-session-pending-access-${e}-${t}`;
  return {
    ...R(),
    ...n,
    queryKey: u,
    braidKey: i,
    enabled: o,
    queryFn: ({ signal: y }) => D.inReview({
      ...K(u, s),
      ...r,
      signal: y,
      key: i,
      params: {
        ...r == null ? void 0 : r.params,
        sessionID: e || "",
        sessionRecurrenceID: t
      }
    })
  };
}, Is = ({
  userId: e,
  sessionId: t,
  recurrenceId: s = "",
  queryClient: n,
  options: r,
  params: o
}) => {
  const u = !!e && !!t, i = u ? we(e, t, s) : E, y = `get-session-request-access-${t}-${s}-${e}`;
  return {
    ...R(),
    ...r,
    queryKey: i,
    braidKey: y,
    enabled: u,
    queryFn: u ? ({ signal: c }) => {
      const _ = { ...o == null ? void 0 : o.params, sessionID: t };
      return s && (_.sessionRecurrenceID = s), D.userSessionRequest({
        ...K(i, n),
        ...o,
        signal: c,
        key: y,
        params: _
      });
    } : l
  };
}, Ls = ({
  options: e,
  params: t
} = {}) => ({
  ...e,
  mutationFn: (s) => D.requestAccess({
    ...t,
    data: s
  })
}), ks = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (s) => D.update({
    ...t,
    data: s
  })
}), gt = (e, t) => [
  L,
  e,
  t,
  P.GRANTED
], Us = (e, t, s) => ({
  queryKey: gt(e, t),
  queryFn: () => D.getViewer({
    params: {
      limit: 50,
      ...s,
      sessionID: e,
      sessionRecurrenceID: t,
      restrictionStatus: P.GRANTED
    }
  }),
  ...F["3m"]
}), $s = ({
  sessionId: e,
  recurrenceId: t = "",
  userId: s,
  onFetched: n,
  onPatch: r,
  onError: o,
  options: u,
  params: i,
  queryClient: y
}) => {
  const c = !!e && !!s, _ = c ? xe(e, t, s) : E, A = `get-session-${e}-${t}-${s}`;
  return {
    ...R(),
    initialData: void 0,
    retry: 2,
    enabled: c,
    ...u,
    queryKey: _,
    braidKey: A,
    queryFn: c ? ({ signal: f }) => p.getById(e, {
      ...i,
      key: A,
      signal: f,
      onFetched: (T) => {
        y.setQueryData(_, T), n && n(T);
      },
      onPatch: r,
      onError: o
    }) : void 0
  };
}, ws = ({
  sessionId: e,
  recurrenceId: t = "",
  options: s,
  params: n
}) => ({
  ...s,
  mutationFn: (r) => p.update(e, t, {
    ...n,
    data: r
  })
}), xs = ({
  sessionId: e
}) => ({
  mutationFn: () => p.end(e)
}), Vs = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: s,
  options: n,
  params: r
}) => {
  const o = e ? Ve(e, t) : E, u = `get-session-presence-${e}-${t}`;
  return {
    ...R(),
    enabled: !!e,
    ...n,
    queryKey: o,
    braidKey: u,
    queryFn: e ? ({ signal: i }) => p.presence(e, {
      ...K(o, s),
      ...r,
      signal: i,
      key: u
    }) : void 0
  };
}, Ws = ({
  sessionId: e,
  params: t,
  options: s
}) => ({
  ...s,
  mutationFn: (n) => p.updateParticipantMetadata(e, {
    ...t,
    data: n
  })
}), x = 50, Y = (e, t) => [G, Re, e, t], js = (e, t) => {
  const s = (t == null ? void 0 : t.limit) || x;
  return {
    queryKey: e ? Y(e, t) : E,
    queryFn: async ({ pageParam: n = 0 }) => p.getFuture({
      params: {
        ...t,
        limit: s,
        cursor: n * s
      }
    }),
    initialPageParam: 0,
    ...F["30s"],
    getNextPageParam: (n, r) => r.length + 1,
    enabled: !!e
  };
}, ct = (e, t) => [G, pe, e, t], zs = (e, t) => {
  const s = (t == null ? void 0 : t.limit) || x;
  return {
    queryKey: e ? ct(e, t) : E,
    queryFn: async ({ pageParam: n = 0 }) => p.getPast({
      params: {
        ...t,
        limit: s,
        cursor: n * s
      }
    }),
    initialPageParam: 0,
    ...F["30s"],
    getNextPageParam: (n, r) => r.length + 1,
    enabled: !!e
  };
}, mt = (e, t) => [G, de, e, t], Js = (e, t) => {
  const s = (t == null ? void 0 : t.limit) || x;
  return {
    queryKey: e ? mt(e, t) : E,
    queryFn: async ({ pageParam: n = 0 }) => p.getMeetingMemory({
      params: {
        ...t,
        limit: s,
        cursor: n * s
      }
    }),
    initialPageParam: 0,
    ...F["30s"],
    getNextPageParam: (n, r) => r.length + 1,
    enabled: !!e
  };
}, Xs = ({
  userId: e,
  accessToken: t,
  params: s
}) => ({
  mutationFn: () => {
    if (!e || !t) throw new Error("Unauthorized");
    return B.activateTrial(e, {
      ...s,
      data: { token: t }
    });
  }
}), Zs = ({
  accessToken: e,
  params: t
}) => ({
  mutationFn: () => ne.hostOptIn({
    data: { accessToken: e },
    ...t
  })
}), Ct = () => [He], hs = () => ({
  queryKey: Ct(),
  queryFn: re.getPlans,
  ...C["1h"]
}), lt = (e, t) => [
  Ye,
  e,
  t
], en = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? lt(e, t) : E,
    queryFn: s ? () => oe.getStream(t) : void 0,
    enabled: s,
    ...F["15m"]
  };
}, tn = ({
  id: e,
  options: t,
  params: s
}) => {
  const n = We(e);
  return {
    ...C["3m"],
    ...t,
    queryKey: n,
    queryFn: ({ signal: r }) => B.getById(e, { ...s, signal: r })
  };
}, At = (e, t) => [
  Be,
  e,
  t
], sn = (e, t, s) => ({
  queryKey: At(e, t),
  queryFn: () => B.getTransactions(e, {
    params: { includeInvoices: "true", ...s }
  }),
  ...C["3m"]
}), pt = (e) => [qe, e], nn = (e, t) => {
  const s = !!e && !!t;
  return {
    queryKey: s ? pt(e) : E,
    queryFn: e ? () => B.getPaymentDetails(e || "") : l,
    ...C["3m"],
    enabled: s
  };
}, dt = () => [Le], rn = () => ({
  queryKey: dt(),
  queryFn: ue.get,
  ...C["1h"]
}), Rt = {
  add: ({ client: e, data: t, userId: s }) => {
    t && m.request.addToPages({
      client: e,
      key: Y(s),
      updateFn: (n) => {
        const r = (n == null ? void 0 : n.sessions) || [];
        return {
          ...n,
          count: ((n == null ? void 0 : n.count) || r.length) + 1,
          sessions: [t, ...r]
        };
      }
    });
  },
  update: ({ client: e, data: t, userId: s }) => {
    t && m.request.updateInPages({
      client: e,
      key: Y(s),
      stopOnChange: !0,
      updateFn: (n) => {
        if (!(n != null && n.sessions)) return n;
        const r = n.sessions.findIndex((u) => u.sessionID === t.sessionID);
        if (r === -1) return n;
        const o = {
          ...n,
          sessions: [...n.sessions]
        };
        return o.sessions[r] = t, o;
      }
    });
  },
  remove: ({ client: e, userId: t, id: s }) => {
    let n;
    return m.request.updateInPages({
      client: e,
      key: Y(t),
      stopOnChange: !0,
      updateFn: (r) => {
        if (!(r != null && r.sessions)) return r;
        const o = r.sessions.findIndex((i) => i.sessionID === s);
        if (o === -1) return r;
        const u = { ...r, sessions: [...r.sessions] };
        return u.count = Math.max(0, (u.count || 1) - 1), n = u.sessions.splice(o, 1)[0], u;
      }
    }), n;
  }
}, Ot = {
  add: ({ client: e, data: t = [] }) => {
    if (!t.length) return;
    const { sessionID: s, sessionRecurrenceID: n } = t[0];
    m.request.update({
      client: e,
      key: [
        L,
        s,
        n,
        P.GRANTED
      ],
      dataOrFn: (r = {}) => {
        var A, f;
        const o = { ...r }, u = (T, I) => (I.type === "domain" ? T[0].push(I) : T[1].push(I), T), [i, y] = ((f = (A = o == null ? void 0 : o.data) == null ? void 0 : A.viewerAccessRules) == null ? void 0 : f.reduce(
          u,
          [[], []]
        )) || [], [c, _] = t.reduce(u, [
          [],
          []
        ]);
        return {
          data: {
            viewerAccessRules: [
              ...c,
              ...i || [],
              ..._,
              ...y || []
            ]
          }
        };
      }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    id: n
  }) => {
    m.request.update({
      client: e,
      key: [
        L,
        t,
        s,
        P.GRANTED
      ],
      dataOrFn: (r) => {
        var u;
        const o = ((u = r == null ? void 0 : r.data.viewerAccessRules) == null ? void 0 : u.filter((i) => i.id !== n)) || [];
        return {
          ...r,
          data: {
            ...r == null ? void 0 : r.data,
            viewerAccessRules: o
          }
        };
      }
    });
  },
  // requests
  addRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    data: n
  }) => {
    var i;
    const r = k(t, s), o = e.getQueryData(r) || j(void 0), u = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((i = o.data) == null ? void 0 : i.accessRules) || [], ...n]
      }
    };
    e.setQueryData(r, u);
  },
  removeRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: s,
    id: n
  }) => {
    var _, A;
    const r = k(t, s), o = e.getQueryData(r);
    if (!((_ = o == null ? void 0 : o.data) != null && _.accessRules)) return;
    const u = o.data.accessRules, i = u == null ? void 0 : u.findIndex((f) => f.id === n);
    if (i === -1) return;
    const y = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((A = o.data) == null ? void 0 : A.accessRules) || []]
      }
    }, c = y.data.accessRules.splice(i, 1)[0];
    return e.setQueryData(r, y), c;
  }
}, on = {
  session: Rt,
  viewerAccess: Ot
};
export {
  kt as CACHE_KEY_BRANCH,
  ee as CACHE_KEY_CALENDAR_EVENT,
  le as CACHE_KEY_CMS_PAGE,
  Ae as CACHE_KEY_CMS_PAGES,
  Mt as CACHE_KEY_CURRENT_USER,
  pe as CACHE_KEY_DASHBOARD_PAST_SESSIONS,
  Re as CACHE_KEY_DASHBOARD_SESSIONS,
  Dt as CACHE_KEY_FEATURED_SESSIONS,
  Jt as CACHE_KEY_HUBSPOT_GET_BINDING_BY_ID,
  ke as CACHE_KEY_HUBSPOT_GET_COMPANY_BY_ID,
  ht as CACHE_KEY_HUBSPOT_GET_CONTACTS,
  es as CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID,
  Xt as CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID,
  Zt as CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  zt as CACHE_KEY_HUBSPOT_PROVIDER,
  Qt as CACHE_KEY_INTEGRATIONS_CONNECTIONS,
  Nt as CACHE_KEY_INTEGRATIONS_DIRECT_URL,
  Yt as CACHE_KEY_INTEGRATIONS_GET_ACTION,
  Ie as CACHE_KEY_INTEGRATIONS_GET_BY_OWNER_SESSION_RECURRENCE,
  Oe as CACHE_KEY_INTEGRATIONS_PROVIDERS,
  ve as CACHE_KEY_LISTENING_MODE_SPEAKERS,
  Ue as CACHE_KEY_LOBBY_ENTER,
  $e as CACHE_KEY_LOBBY_SUMMARY,
  Ke as CACHE_KEY_MEETING_MEMORY_MESSAGES,
  de as CACHE_KEY_MEETING_MEMORY_SESSIONS,
  vt as CACHE_KEY_MEETING_MEMORY_SUGGESTIONS,
  fe as CACHE_KEY_MEETING_MEMORY_THREAD,
  Te as CACHE_KEY_MEETING_MEMORY_THREADS,
  bt as CACHE_KEY_PAST_SESSIONS,
  qe as CACHE_KEY_PAYMENT_DETAILS,
  G as CACHE_KEY_PRIVATE_BASE,
  Fe as CACHE_KEY_RECORDING,
  Ht as CACHE_KEY_RECORDING_STATUS,
  wt as CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID,
  $t as CACHE_KEY_SALESFORCE_GET_BINDING_BY_ID,
  Wt as CACHE_KEY_SALESFORCE_GET_CONTACTS,
  jt as CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID,
  xt as CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID,
  Vt as CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  Ut as CACHE_KEY_SALESFORCE_PROVIDER,
  Bt as CACHE_KEY_SESSION,
  Gt as CACHE_KEY_SESSION_ACCESS_RULE,
  L as CACHE_KEY_SESSION_ACCESS_RULES,
  Me as CACHE_KEY_SESSION_AI_FEED,
  be as CACHE_KEY_SESSION_END_SUMMARY,
  De as CACHE_KEY_SESSION_EPISODES,
  Ne as CACHE_KEY_SESSION_GET_CARDS,
  It as CACHE_KEY_SESSION_PENDING_RULES_STATS,
  Qe as CACHE_KEY_SESSION_PRESENCE,
  te as CACHE_KEY_SESSION_REQUEST_ACCESS,
  Pe as CACHE_KEY_SESSION_V2,
  Lt as CACHE_KEY_SESSION_VIEWERS,
  Ye as CACHE_KEY_STREAM_TOKEN,
  He as CACHE_KEY_SUBSCRIPTION_PLANS,
  Be as CACHE_KEY_SUBSCRIPTION_RECEIPTS,
  Ge as CACHE_KEY_USER,
  Le as CACHE_KEY_USER_MEETING_TYPES,
  Xs as activateTrialMutation,
  F as activeOptions,
  rs as aiFeedCacheService,
  gs as aiFeedEventMutation,
  R as baseBraidOptions,
  ts as buildPrivateQueryKey,
  m as cacheService,
  Cs as calendarEventQuery,
  As as cmsPageQuery,
  ps as cmsPagesQuery,
  Rs as createCRMBindingMutation,
  st as defaultBraidOnFetched,
  nt as defaultBraidOnPatch,
  _s as disabledFetchOptions,
  E as emptyCacheKey,
  l as emptyFn,
  xs as endSessionMutation,
  js as futureSessionsQuery,
  S as getActiveOptions,
  N as getAiFeedQueryKey,
  ds as getCRMProviderBindingsForSessionQuery,
  it as getCRMProviderBindingsForSessionQueryKey,
  ms as getCalendarEventQueryKey,
  ls as getCardsQuery,
  rt as getCardsQueryKey,
  ot as getCmsPageQueryKey,
  ut as getCmsPagesQueryKey,
  K as getDefaultBraidCallbacks,
  Y as getFutureSessionQueryKey,
  g as getInactiveOptions,
  as as getInfinityQueryRequestData,
  et as getInfinityRequestData,
  yt as getIntegrationsProvidersQueryKey,
  ns as getListeningModeSpeakersQueryKey,
  ze as getLobbyEnterQueryKey,
  Ds as getLobbySummaryQuery,
  Je as getLobbySummaryQueryKey,
  mt as getMeetingMemorySessionsQueryKey,
  q as getMeetingMemoryThreadMessagesQueryKey,
  ss as getMeetingMemoryThreadQueryKey,
  $ as getMeetingMemoryThreadsQueryKey,
  ct as getPastSessionsQueryKey,
  pt as getPaymentDetailsQueryKey,
  hs as getPlansQuery,
  Ct as getPlansQueryKey,
  is as getQueryRequestData,
  _t as getRecordingEpisodesQueryKey,
  St as getRecordingQueryKey,
  he as getRequestData,
  Ss as getSessionAiFeedQuery,
  je as getSessionEndSummaryQueryKey,
  gt as getSessionGrantedAccessRulesQueryKey,
  Ve as getSessionPresenceKey,
  $s as getSessionQuery,
  xe as getSessionQueryKey,
  lt as getStreamTokenQueryKey,
  dt as getUserMeetingTypesQueryKey,
  We as getUserQueryKey,
  At as getUserTransactionsQueryKey,
  Zs as hostOptInMutation,
  at as hubspotGetCompanyQueryKey,
  Os as hubstopGetCompanyQuery,
  C as inactiveOptions,
  us as initConfig,
  Ks as integrationsProvidersQuery,
  tt as jsonPatch,
  ys as jsonPatchArrayFallbackValidator,
  bs as lobbyEnterQuery,
  O as logger,
  os as meetingMemoryCacheService,
  vs as meetingMemoryDeleteThreadMutation,
  Js as meetingMemorySessionsQuery,
  Ys as meetingMemoryThreadMessagesQuery,
  Ns as meetingMemoryThreadQuery,
  Qs as meetingMemoryThreadsQuery,
  a as milliseconds,
  zs as pastSessionsQuery,
  nn as paymentDetailsQuery,
  Et as queryDataDefaultOptions,
  Hs as recordingEpisodesQuery,
  Bs as recordingQuery,
  Ls as requestAccessMutation,
  Fs as requestLobbyAccessMutation,
  on as sessionCacheService,
  cs as sessionEndSummaryQuery,
  Us as sessionGrantedAccessRulesQuery,
  Gs as sessionPendingAccessQuery,
  k as sessionPendingAccessQueryKey,
  Vs as sessionPresenceQuery,
  Ms as startLobbySessionMutation,
  en as streamTokenQuery,
  Ws as updateParticipantMetadataMutation,
  ks as updateSessionAccessMutation,
  ws as updateSessionMutation,
  Ps as updateSlugMutation,
  Es as useBraid,
  fs as useInfiniteQuery,
  Ts as useQuery,
  qs as useQueryData,
  rn as userMeetingTypesQuery,
  tn as userQuery,
  Is as userSessionRequestAccessQuery,
  we as userSessionRequestAccessQueryKey,
  sn as userTransactionsQuery
};
