import { format as u } from "date-fns";
import { VideoFileExtension as g, SocialKeys as w } from "@waitroom/models";
const F = (t) => t != null, d = (t) => typeof t == "function", S = d, T = (t) => S(t) ? t() : t, x = (t, ...n) => n.some((e) => typeof t === e), N = (t, n) => n in t, L = (t, n) => t instanceof n, j = (...t) => (n) => t.reduceRight((e, r) => r(e), n), U = (...t) => (n) => t.reduce((e, r) => r(e), n), v = (t) => (...n) => Promise.resolve(t(...n)), P = (t) => (n) => {
  try {
    return t();
  } catch (e) {
    return n ? n(e) : e;
  }
}, Y = (t) => async (n) => {
  try {
    return await t();
  } catch (e) {
    return n ? n(e) : e;
  }
}, k = async (t, n) => {
  var o;
  const e = {
    delay: 300,
    retries: 2,
    ...n
  };
  let r;
  for (let s = 1; s <= e.retries; s++)
    try {
      return await t();
    } catch (i) {
      if (r = i, (o = e.shouldSkip) != null && o.call(e, i)) throw i;
      s < e.retries && await new Promise((c) => setTimeout(c, (e.delay ?? 300) * s));
    }
  throw r;
}, C = (t, n) => {
  let e = Date.now();
  const r = async (o = 1) => {
    var c;
    const s = {
      initialDelay: 300,
      maxDelay: 5e3,
      retries: 3,
      resetTime: 1e4,
      // 10s
      ...n
    }, i = Date.now();
    i - e > s.resetTime && (o = 1), e = i;
    try {
      return await t();
    } catch (f) {
      if (s.onError && await s.onError(f), (c = s.shouldSkip) != null && c.call(s, f) || s.retries !== -1 && o >= s.retries) throw f;
      return s.onRetry && await s.onRetry(f, o), new Promise(
        (y) => setTimeout(
          async () => y(r(o + 1)),
          Math.min(s.initialDelay * 2 ** (o - 1), s.maxDelay)
        )
      );
    }
  };
  return r();
}, b = (t) => (n) => (...e) => t(e) ? n(e) : void 0, z = () => typeof window < "u", _ = (t, n) => {
  const e = {};
  if (!t) return e;
  for (let r = 0; r < t.length; r++) {
    const o = t[r];
    n ? n(e, o) : e[String(o)] = o;
  }
  return e;
}, q = (t, n) => t ? (Array.isArray(n) ? n : [n]).every((r) => t.includes(r)) : !1, H = (t, n) => t ? (Array.isArray(n) ? n : [n]).some((r) => t.includes(r)) : !1, I = (t, n, e = ", ") => {
  if (!t) return "";
  const r = d(n) ? n : (o) => String(o[n] ?? "");
  return t.map(r).filter(Boolean).join(e);
}, J = (t, n, e) => {
  if (!t) return "";
  const r = d(n) ? n : (c) => String(c[n] ?? ""), o = t.map(r).filter(Boolean);
  if (o.length <= 1) return o.join(", ");
  const s = [...o], i = s.pop();
  return `${s.join(", ")} ${e} ${i}`;
};
function V(t, n) {
  return [...t.filter((e) => n.indexOf(e) === -1), ...n.filter((e) => t.indexOf(e) === -1)];
}
function Z(t) {
  const n = Math.floor(Math.random() * t.length);
  return t[n];
}
function W(t, n) {
  const e = {}, r = [];
  for (const o of t) {
    const s = o[n];
    e[s] || (e[s] = !0, r.push(o));
  }
  return r;
}
const a = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], l = 1024, G = (t, n = 2) => {
  if (t === 0) return "0 B";
  const e = n < 0 ? 0 : n, r = Math.floor(Math.log(t) / Math.log(l));
  return `${parseFloat((t / l ** r).toFixed(e))} ${a[r]}`;
}, Q = (t, n = "B", e = "MB", r = 2) => {
  if (!t || n === e) return t;
  const o = r < 0 ? 0 : r, s = a.indexOf(e), i = a.indexOf(n);
  if (s < 0 || i < 0) return t;
  const c = s - i;
  return parseFloat((t / l ** c).toFixed(o));
}, p = /:00/, K = 864e5, X = 36e5, tt = 6e4, nt = (t) => t ? new Date(t) : /* @__PURE__ */ new Date(), et = (t, n, {
  separator: e = " — ",
  date: r = "EEEE, MMM d, yyyy",
  time: o = "h:mm a"
} = {}) => {
  const s = u(t, `${r}${e}${o}`).replace(p, ""), i = u(n, o).replace(p, "");
  return `${s} - ${i}`;
}, h = (t) => {
  const e = ~~((t < 0 ? 0 : t) / 1e3), r = ~~(e / 60), o = ~~(r / 60);
  return [e % 60, r % 60, o % 24, ~~(o / 24)];
}, rt = (t, n) => {
  const e = n - t;
  return e < 0 ? 0 : e;
}, ot = (t, n) => {
  const e = n - t;
  return h(e);
}, m = ["s", "m", "h", "d"], E = (t, n = m) => {
  let e = "";
  for (let r = t.length - 1; r >= 0; r--) {
    const o = t[r], s = n[r];
    o > 0 && s && (e += `${o}${n[r]} `);
  }
  return e.trimEnd();
}, st = (t, n = m) => {
  const e = h(t);
  return E(e, n);
}, it = (t) => typeof t == "number" ? t * 1e3 : t, ct = (t, n) => {
  const e = new Date(t), r = [`FREQ=${n}`];
  return n === "weekly" ? r.push(`BYDAY=${u(e, "EEEEEE")}`) : n === "monthly" ? r.push(`BYMONTHDAY=${u(e, "d")}`) : n === "yearly" && r.push(`BYMONTH=${u(e, "M")};BYMONTHDAY=${u(e, "d")}`), r.join(";").toUpperCase();
}, ut = (t, n = ":") => {
  const [e, r, o, s] = h(t), i = [];
  let c = o;
  return s > 0 && (c += s * 24), c > 0 ? (i.push(String(c)), i.push(String(r).padStart(2, "0"))) : i.push(String(r)), i.push(String(e).padStart(2, "0")), i.join(n);
}, ft = (t, n, e, r) => {
  n.forEach((o) => {
    t.addEventListener(o, e, r);
  });
}, at = (t, n, e, r) => {
  n.forEach((o) => {
    t.removeEventListener(o, e, r);
  });
}, lt = (t = "") => new DOMParser().parseFromString(t, "text/html").documentElement.textContent || t, dt = (t, n, e, r = {}) => t.reduce((o, s) => {
  const i = e[s] ?? n[s];
  return o[s] = s in r || i === void 0 ? i : String(i), o;
}, {});
function ht(t) {
  try {
    return JSON.parse(t), !0;
  } catch {
    return !1;
  }
}
function pt(t, n) {
  if (!t) return n;
  try {
    return JSON.parse(t);
  } catch {
    return n;
  }
}
const $ = typeof navigator < "u" && "locks" in navigator, mt = (t, n, e = {}) => $ ? (...r) => navigator.locks.request(t, e, async () => await n(...r)) : n, yt = (t = 0, n = Number.MAX_SAFE_INTEGER) => Math.floor(Math.random() * (n - t + 1)) + t, gt = (t, n, e = "+ ") => t > n ? `${e}${n}` : `${t}`, wt = (t) => t < 1e3 ? `${t}` : t < 1e6 ? `${Number((t / 1e3).toFixed(1))}k` : `${Number((t / 1e6).toFixed(1))}m`, M = (t) => t != null && (typeof t == "string" || typeof t == "number"), St = (t) => t !== null && typeof t == "object" && !Array.isArray(t), O = (t) => Object.keys(t);
function Et(t, ...n) {
  return n.length ? n.reduce((r, o) => {
    const { [o]: s, ...i } = r;
    return i;
  }, t) : t;
}
function $t(t, n, e) {
  if (!t) return e;
  const o = (typeof n == "string" ? n.split(".") : n).reduce((s, i) => s && s[i] !== void 0 ? s[i] : void 0, t);
  return o !== void 0 ? o : e;
}
function B(t, n, e) {
  return O({ ...t, ...n }).reduce((r, o) => ((e ? e(t[o]) === e(n[o]) : t[o] === n[o]) || (r[o] = n[o]), r), {});
}
const Mt = (t, n) => Object.entries(B(t, n)).length === 0, Ot = (t, n) => {
  if (typeof t != "object" || typeof n != "object" || t === null || n === null)
    return t === n;
  const e = Object.keys(t), r = Object.keys(n);
  if (e.length !== r.length) return !1;
  for (const o of e)
    if (!Object.prototype.hasOwnProperty.call(n, o) || t[o] !== n[o])
      return !1;
  return !0;
}, Bt = (t, n) => {
  if (!t) return;
  const e = {};
  for (let r = 0; r < t.length; r++) {
    const o = t[r], s = typeof n == "function" ? n(o) : o[n], i = M(s) ? s : String(s);
    e[i] = (e[i] || []).concat(o);
  }
  return e;
}, At = (t, n = g.HLS) => {
  for (let e = t.length - 1; e > -1; e -= 1)
    if (t[e].endsWith(n))
      return t[e];
  return t[0];
}, Dt = async (t) => {
  var r;
  const n = (r = t == null ? void 0 : t.getReader) == null ? void 0 : r.call(t);
  if (!n) return;
  const e = await n.read();
  return new TextDecoder().decode(e.value);
}, Rt = (t, n, e = "...") => !t || t.length <= n ? t : `${t.substring(0, n)}${e}`, Ft = (t) => !!t && t.trim() !== "", Tt = (t) => !!t && String(t).toLowerCase() !== "false" && t !== "0", xt = ({
  text: t,
  /** Lenght of the step for reducing the size */
  lengthStep: n,
  /** Size reduction for each step  */
  sizeStep: e = 0.1,
  /** Minimum size */
  min: r = 0.5
}) => {
  const o = (t || "").length, s = 1 - Math.floor(o / n) * e;
  return s < r ? r : s;
}, Nt = `  
`, Lt = (t, n = "-") => t.join(n), jt = (t = "") => t === "" ? "" : ` ${t}`, Ut = ({
  params: t,
  relative: n = !1
} = {}) => {
  const e = new URL(window.location.href);
  if (t)
    for (const r of Object.keys(t))
      e.searchParams.set(r, String(t[r]));
  return n ? `${e.pathname}${e.search}` : e.href;
}, vt = ({
  relative: t = !1,
  queryString: n = !0
} = {}) => {
  const e = new URL(window.location.href);
  return n || (e.search = ""), t ? `${e.pathname}${e.search}` : e.href;
}, Pt = (t) => t ? /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
  t
) : !1, A = new RegExp("^(?:[a-z+]+:)?//", "i"), Yt = (t) => !!t && A.test(t), kt = (t, n) => {
  const e = new URL(t);
  for (const r of Object.keys(n))
    e.searchParams.set(r, String(n[r]));
  return e.href;
};
function Ct(t) {
  return t && t.replace(/(wss?:\/\/|wss?:)/g, "").replace("/socket", "").replace("/websocket", "");
}
const bt = (t) => t ? t == null ? void 0 : t.reduce((n, e) => (e.platform === "email" || (n[e.platform] = e.url), n), {}) : void 0, zt = (t) => t ? w.reduce((n, e) => {
  const r = t[e];
  return !r || e === "email" || n.push({
    platform: e,
    url: r
  }), n;
}, []) : void 0;
export {
  K as MS_DAY,
  X as MS_HOUR,
  tt as MS_MINUTE,
  kt as addQueryParams,
  Mt as areDeepEqual,
  _ as arrayToMap,
  ft as bindEvents,
  Ut as buildCurrentUrl,
  Lt as buildKey,
  xt as calcResponsiveSize,
  j as compose,
  q as containsAll,
  H as containsAny,
  Q as convertBytes,
  nt as dateOrNow,
  lt as decodeHtmlEntities,
  B as diff,
  V as difference,
  m as durationSuffixDefaults,
  st as durationToFormat,
  bt as flattenSocials,
  G as formatBytes,
  et as fromTo,
  $t as get,
  vt as getCurrentUrl,
  Ct as getDomainKeyFromUrl,
  rt as getDuration,
  ot as getDurationParsed,
  ut as getFormattedLength,
  Z as getRandomItem,
  yt as getRandomNumber,
  At as getRecordingURL,
  Bt as groupBy,
  b as ifThen,
  F as isDefined,
  S as isFn,
  d as isFunction,
  L as isInstanceOf,
  ht as isJsonString,
  Ft as isNotEmpty,
  St as isObject,
  Yt as isRelativeUrl,
  N as isType,
  x as isTypeof,
  M as isValidKey,
  Pt as isValidUrl,
  I as joinOn,
  J as joinWithAnd,
  O as keysOf,
  dt as mergeValues,
  l as multiplier,
  $ as navigatorLockSupported,
  Nt as newline,
  Et as omit,
  T as orFn,
  pt as parseJson,
  h as parseMilliseconds,
  Dt as parseStream,
  E as parsedDurationToFormat,
  U as pipe,
  v as promisify,
  k as retry,
  C as retryWithBackoff,
  Ot as shallowCompare,
  wt as shortenNumber,
  a as sizes,
  Tt as toBoolean,
  it as toEpoch,
  gt as toLimit,
  ct as toRRule,
  Rt as truncate,
  P as tryCatch,
  Y as tryCatchAsync,
  at as unbindEvents,
  zt as unflattenSocials,
  W as uniqBy,
  z as windowDefined,
  mt as withLock,
  jt as withSpace
};
